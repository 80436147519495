<template>
  <div>
    <div v-if="pageReady" class="mx-5">
      <div
        class="container d-flex flex-column container align-items-start mb-5 p-4"
      >
        <h2 class="pt-5 my-5 fs-3 text-dark">Teleconsult Appointments</h2>

        <div class="my-3">
          <div>
            <label class="form-check-label">Unable to contact patient</label>

            <BaseRadioGroup
              v-model="admin.unable_to_contact_patient"
              :options="defaultOptions"
              :inline="true"
              :label="`unable_to_contact_patient`"
            />
          </div>
        </div>

        <div
          v-if="nurse_included"
          class="d-flex justify-content-between align-items-end my-3 w-100"
        >
          <div class="w-50 me-5">
            <label class="form-check-label" for="admin.nurse_appointment_date">
              Nurse Appointment Time and Date
            </label>
            <datepicker
              id="admin.nurse_appointment_date"
              v-model="admin.nurse_appointment"
              :model-value="convertToDateFormat(admin.nurse_appointment)"
              class="datepicker-input-box has-validation"
              :class="adminErrors.nurse_appointment ? 'border-danger' : ''"
              autocomplete="off"
              input-format="hh:mm aaa , dd MMMM yyyy"
              :upper-limit="currentYear"
              minimum-view="time"
              style="
                --vdp-selected-bg-color: #32c3d6;
                --vdp-hover-bg-color: #32c3d6;
                --vdp-bg-color: #fff;
              "
              starting-view="month"
            />
            <div v-if="adminErrors.date_of_birth" class="error-warning">
              {{ adminErrors.date_of_birth[0] }}
            </div>
          </div>
          <div class="d-flex flex-column justify-content-between w-75">
            <Select2
              v-model="admin.nurse"
              class="d-flex"
              :data-array="nurseList"
              :api-call-name="`getTPDNurseList`"
              :input-name="`Nurse`"
              :label-text="`Registered Nurse`"
            >
              <template #no-options>
                No nurse available on this date, please enter another date...
              </template>
              <template #option="option">
                <div
                  v-if="option"
                  class="py-3 px-2 vs-dropdown-listing rounded-3"
                >
                  <div v-if="option.img" class="select2-tpd-list-icon">
                    <img :src="option.img" :alt="option.id" />
                  </div>
                  <span class="d-block fw-bold text-capitalize">
                    {{ option.first_name }} {{ ' ' }}
                    {{ option.last_name }}
                  </span>
                  <em>
                    {{ option.email }}
                  </em>
                </div>
              </template>
            </Select2>
            <div v-if="adminErrors.nurse" class="error-warning">
              {{ adminErrors.nurse[0] }}
            </div>
          </div>
        </div>
        <div v-if="nurse_included" class="row my-3">
          <div class="col">
            <FormInput
              v-model:modelValue="admin.nurse_appointment_duration"
              :type="`number`"
              :error="adminErrors.nurse_appointment_duration"
              :label="`admin.nurse_appointment_duration`"
            >
              <label
                class="form-check-label"
                for="admin.nurse_appointment_duration"
              >
                Duration of nurse appointment (min)
              </label>
            </FormInput>
          </div>
        </div>

        <!-- //doctor -->
        <div class="d-flex justify-content-start align-items-end my-3 w-100">
          <div class="w-50 me-5">
            <label class="form-check-label" for="admin.doc_appointment_date">
              Doctor Appointment Time and Date
            </label>
            <datepicker
              id="admin.doc_appointment_date"
              v-model="admin.doctor_appointment"
              class="datepicker-input-box"
              :class="adminErrors.doctor_appointment ? 'border-danger' : ''"
              :model-value="convertToDateFormat(admin.doctor_appointment)"
              autocomplete="off"
              input-format="hh:mm aaa , dd MMMM yyyy"
              :upper-limit="currentYear"
              minimum-view="time"
              style="
                --vdp-selected-bg-color: #32c3d6;
                --vdp-hover-bg-color: #32c3d6;
                --vdp-bg-color: #fff;
              "
              starting-view="month"
            />
            <div v-if="adminErrors.doctor_appointment" class="error-warning">
              {{ adminErrors.doctor_appointment[0] }}
            </div>
          </div>
          <div class="d-flex flex-column justify-content-between w-75">
            <Select2
              v-model="admin.doctor"
              class="d-flex"
              :label-text="`Registered Doctor`"
              :data-array="doctorList"
              :api-call-name="`getTPDDoctorList`"
              :input-name="`Doctor`"
            >
              <template #no-options>
                No doctor available on this date, please enter another date...
              </template>
              <template #option="option">
                <div
                  v-if="option"
                  class="py-3 px-2 vs-dropdown-listing rounded-3"
                >
                  <div v-if="option.img" class="select2-tpd-list-icon">
                    <img :src="option.img" :alt="option.id" />
                  </div>
                  <span class="d-block fw-bold text-capitalize">
                    {{ option.first_name }} {{ ' ' }}
                    {{ option.last_name }}
                  </span>
                  <em>
                    {{ option.email }}
                  </em>
                </div>
              </template>
            </Select2>
            <div v-if="adminErrors.doctor" class="error-warning">
              {{ adminErrors.doctor[0] }}
            </div>
          </div>
        </div>
        <div class="row my-3">
          <div class="col">
            <FormInput
              v-model:modelValue="admin.doctor_appointment_duration"
              :type="`number`"
              :error="adminErrors.nurse_appointment_duration"
              :label="`admin.doc_appointment_duration`"
            >
              <label
                class="form-check-label"
                for="admin.doc_appointment_duration"
              >
                Duration of doctor appointment (min)
              </label>
            </FormInput>
          </div>
        </div>

        <!-- //specialist  -->
        <div
          v-if="specialist_included"
          class="d-flex justify-content-start align-items-end my-3 w-100"
        >
          <div class="w-50 me-5">
            <label
              class="form-check-label"
              for="admin.specialist_appointment_date"
            >
              Specialist Appointment Time and Date
            </label>
            <datepicker
              id="admin.specialist_appointment_date"
              v-model="admin.specialist_appointment"
              :model-value="convertToDateFormat(admin.specialist_appointment)"
              :class="adminErrors.specialist_appointment ? 'border-danger' : ''"
              autocomplete="off"
              class="datepicker-input-box"
              input-format="hh:mm aaa , dd MMMM yyyy"
              :upper-limit="currentYear"
              minimum-view="time"
              style="
                --vdp-selected-bg-color: #32c3d6;
                --vdp-hover-bg-color: #32c3d6;
                --vdp-bg-color: #fff;
              "
              starting-view="month"
            />
            <div
              v-if="adminErrors.specialist_appointment"
              class="error-warning"
            >
              {{ adminErrors.specialist_appointment[0] }}
            </div>
          </div>
          <div class="d-flex flex-column justify-content-between w-75">
            <Select2
              v-model="admin.specialist"
              class="d-flex"
              :label-text="`Registered Specialist`"
              :data-array="specialistList"
              :api-call-name="`getTPDSpecialistList`"
              :input-name="`Specialist`"
            >
              <template #no-options>
                No specialist available on this date, please enter another
                date...
              </template>
              <template #option="option">
                <div
                  v-if="option"
                  class="py-3 px-2 vs-dropdown-listing rounded-3"
                >
                  <div v-if="option.img" class="select2-tpd-list-icon">
                    <img :src="option.img" :alt="option.id" />
                  </div>
                  <span class="d-block fw-bold text-capitalize">
                    {{ option.first_name }} {{ ' ' }}
                    {{ option.last_name }}
                  </span>
                  <em>
                    {{ option.email }}
                  </em>
                </div>
              </template>
            </Select2>
            <div v-if="adminErrors.specialist" class="error-warning">
              {{ adminErrors.specialist[0] }}
            </div>
          </div>
        </div>
        <div v-if="include_specialist" class="row my-3">
          <div class="col">
            <FormInput
              v-model:modelValue="admin.specialist_appointment_duration"
              :type="`number`"
              :error="adminErrors.specialist_appointment_duration"
              :label="`admin.specialist_appointment_duration`"
            >
              <label
                class="form-check-label"
                for="admin.specialist_appointment_duration"
              >
                Duration of specialist appointment (min)
              </label>
            </FormInput>
          </div>
        </div>
      </div>

      <!-- selected services section -->
      <InfoBox :title="`Selected Service Requirement(s) & Fees`">
        <div class="container row mt-4">
          <div class="col">
            <p class="fw-bold">Service Requirement(s)</p>
            <ul>
              <li v-for="item in serviceAndFeesInfo" :key="item">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="col">
            <p class="fw-bold">Fees</p>
            <ul class="">
              <li>
                {{ fee }}
              </li>
            </ul>
          </div>
        </div>
      </InfoBox>

      <!-- patient details section -->
      <div id="patient_info_section" class="container my-5 p-4">
        <h2 class="pt-5 my-5 fs-3 text-dark">Patient’s Details</h2>
        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay
              :label="'First Name'"
              :value="patient.first_name"
            />
          </div>

          <div class="col">
            <BaseInputDisplay
              :label="'Middle Name'"
              :value="patient.middle_name"
            />
          </div>

          <div class="col">
            <BaseInputDisplay :label="'Last Name'" :value="patient.last_name" />
          </div>
        </div>

        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay :label="'Gender'" :value="patient.gender" />
          </div>
          <div class="col">
            <BaseInputDisplay
              :label="'Date of Birth'"
              :value="formatDate(patient.date_of_birth)"
            />
          </div>

          <div class="col">
            <BaseInputDisplay
              :label="'Contact Number'"
              :value="patient.contact_number"
            />
          </div>
        </div>

        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay
              :label="'EMR Number'"
              :value="patient.emr_number"
            />
          </div>
          <div class="col">
            <BaseInputDisplay
              :label="'Membership/Claim Number'"
              :value="patient.claim_number"
            />
          </div>

          <div class="col">
            <BaseInputDisplay :label="'Email'" :value="patient.email" />
          </div>
        </div>

        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay
              :label="'Current Address Line 1'"
              :value="patient.street_address"
            />
          </div>
        </div>

        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay :label="'Suburb'" :value="patient.suburb" />
          </div>
          <div class="col">
            <BaseInputDisplay :label="'State'" :value="patient.state" />
          </div>
          <div class="col">
            <BaseInputDisplay :label="'Country'" :value="patient.country" />
          </div>
          <div class="col">
            <BaseInputDisplay
              :label="'ZIP/Postal Code'"
              :value="patient.postcode"
            />
          </div>
        </div>
      </div>

      <!-- lawyer details section -->
      <div id="patient_info_section" class="container my-5 p-4">
        <h2 class="pt-5 my-5 fs-3 text-dark">Lawyer’s Details</h2>
        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay
              :label="'First Name'"
              :value="lawyer.first_name"
            />
          </div>

          <div v-if="lawyer.middle_name" class="col">
            <BaseInputDisplay
              :label="'Middle Name'"
              :value="lawyer.middle_name"
            />
          </div>

          <div class="col">
            <BaseInputDisplay :label="'Last Name'" :value="lawyer.last_name" />
          </div>
        </div>

        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay :label="'Company'" :value="lawyer.company_name" />
          </div>

          <div class="col">
            <BaseInputDisplay
              :label="'Contact Number'"
              :value="lawyer.contact_number"
            />
          </div>

          <div class="col">
            <BaseInputDisplay :label="'Email'" :value="lawyer.email" />
          </div>
        </div>
        <div class="my-4 row">
          <div class="col-12">
            <BaseInputDisplay :label="'Address'" :value="lawyer.address" />
          </div>
        </div>
      </div>

      <!-- new reports -->
      <div class="container my-3 p-4">
        <h2 class="pb-3 fs-3 text-dark">Report Formats</h2>
        <div v-if="newReports.value.length > 0" class="mt-2">
          <div
            v-for="reportFiles in newReports.value"
            :key="reportFiles.display_name"
            style="font-size: 13px"
            class="d-flex align-items-center"
          >
            <span class="circle-success me-1"></span>
            <div>
              <a
                v-if="reportFiles.display_name"
                :href="reportFiles.tpd_detail.file"
                target="_blank"
              >
                {{ reportFiles.display_name }}
              </a>
            </div>
          </div>
        </div>
        <div v-else>No new reports</div>
      </div>

      <!-- patient document section -->
      <div id="patient_document_section" class="container my-3 p-4">
        <MedicalDocUploader
          :medical-docs="lawyerDocuments"
          :card-header="`Lawyer's Uploads`"
          :read-only="true"
          @download-file="downloadFile"
        >
          <div class="mb-4">
            <h2 class="mt-5 fs-3 text-dark">Patient's Documents</h2>
            <div class="d-flex align-items-center fst-italic fw-light py-2">
              <img src="@/assets/svg/warning.svg" class="me-2" />
              Please download files from laywer and confirm your medical file
              generation method
            </div>
          </div>

          <template #legends>
            <div class="my-4 mx-auto w-50 d-flex justify-content-center">
              <div
                v-for="legend in medicalDocLegends"
                :key="legend.title"
                class="mx-2"
              >
                <img
                  :src="require(`@/assets/svg/${legend.status}.svg`)"
                  class="me-2"
                />
                <span>{{ legend.title }}</span>
              </div>
            </div>
          </template>
        </MedicalDocUploader>

        <div class="my-4" />
        <div>
          <p class="d-inline me-5">Medical file created on local system?</p>
          <span class="text-danger mb-2 d-block fs-8">
            Please ensure admin downloaded files above to be reviewed and
            uploaded to own local system before selecting yes
          </span>
          <BaseRadioGroup
            v-model="isLocallyMadeMedFile"
            :disabled="!enableLocallyMadeMedFile"
            :options="defaultOptions"
            :label="`isLocallyMadeMedFile`"
          />
        </div>
      </div>

      <div class="d-grid col-4 mt-5 mx-auto">
        <button
          type="button"
          class="btn btn-success rounded-3 text-white my-3"
          @click="submit"
        >
          BOOK APPOINTMENT
        </button>
      </div>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import FormInput from '@/components/TPD/Components/FormInput.vue'
import Select2 from '@/components/Widget/SelectTwo.vue'
import InfoBox from '@/components/TPD/Widgets/InfoBox.vue'
// import FileDropBox from '@/components/TPD/Widget/DragDropFileUpload.vue'
// import FilePreview from '@/components/TPD/Widget/FilePreview.vue'
import axios from 'axios'
import MedicalDocUploader from '@/components/TPD/Components/MedicalDocUploader.vue'

import dayjs from 'dayjs'

import BaseInputDisplay from '@/components/TPD/Base/BaseInputDisplay.vue'

import { getLastDayOfYear } from '@/utils/generics.js'

// import { parseISO } from 'date-fns'

export default {
  name: 'TpdAdminForm',
  components: {
    Datepicker,
    Select2,
    FormInput,
    InfoBox,
    // FilePreview,
    // FileDropBox,
    BaseRadioGroup,
    MedicalDocUploader,
    BaseInputDisplay,
  },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:readyNext', 'update:toastText'],

  data() {
    return {
      serviceType: null,
      admin: {
        nurse_appointment_duration: 10,
        doctor_appointment_duration: 45,
        doctor_appointment: null,
        nurse_appointment: null,
        unable_to_contact_patient: false,
      },
      adminErrors: {},
      patient: {},
      lawyer: {},
      serviceAndFeesInfo: [],
      fee: null,
      files: [],
      fileUrl: null,
      //
      nurseList: [],
      nurse_included: false,

      //
      doctorList: [],

      //
      specialistList: [],
      include_specialist: false,

      //
      isLocallyMadeMedFile: false,
      //

      //patient documents that is taken from lawyer page
      lawyerDocuments: [
        {
          title: 'GP Notes',

          api_title: 'gp_notes',
          status: 'unavailable',
          value: [],
          is_all_marked: false,
        },
        {
          title: 'Specialist Documents',

          api_title: 'specialist_docs',
          status: 'unavailable',
          is_all_marked: false,
          value: [],
        },
        {
          title: 'Surgical Note',

          api_title: 'surgical_notes',
          status: 'unavailable',
          is_all_marked: false,
          value: [],
        },
        {
          title: 'Investigation reports',

          api_title: 'investigation_reports',
          status: 'unavailable',
          is_all_marked: false,
          value: [],
        },
        {
          title: 'Hospital Record',

          api_title: 'hospital_records',
          status: 'unavailable',
          is_all_marked: false,
          value: [],
        },
        {
          title: 'Medicolegal Documents',

          api_title: 'medicolegal_docs',
          status: 'unavailable',
          is_all_marked: false,
          value: [],
        },
        {
          title: 'Briefing Letters',

          api_title: 'legal_letters',
          status: 'unavailable',
          is_all_marked: false,
          value: [],
        },
        {
          title: 'Other Documents',

          api_title: 'other_docs',
          status: 'unavailable',
          is_all_marked: false,
          value: [],
        },
      ],
      //
      newReports: {
        title: 'Report Formats',
        api_title: 'new_reports',
        status: 'unavailable',
        value: [],
      },
      //

      defaultOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      //legends
      medicalDocLegends: [
        { title: 'Included', status: 'included' },

        { title: 'Not Available', status: 'unavailable' },
      ],
      genderOptions: [
        {
          value: 'M',
          label: 'Male',
        },
        {
          value: 'F',
          label: 'Female',
        },
      ],
      pageReady: false,
    }
  },

  computed: {
    currentYear() {
      return getLastDayOfYear()
    },

    enableLocallyMadeMedFile() {
      let incompleteAry = []

      this.lawyerDocuments.forEach((category) => {
        if (category.status !== 'unavailabe' && !category.is_all_marked) {
          return incompleteAry.push(category.api_title)
        }
      })

      return incompleteAry.length > 0 ? false : true
    },
  },

  async mounted() {
    await this.getNurseList()
    await this.getDocList()
    await this.getSpecialistList()
    // this.lawyerDocuments = this.$store.state.patientDocs
    await this.getAsssesment()
  },

  methods: {
    parseStringToDate(date) {
      return new Date(dayjs(date))
    },

    formatDate(date) {
      if (date) {
        const result = dayjs(date).format('DD-MM-YYYY')

        return result
      } else return null
    },

    convertToDateFormat(date) {
      if (date) {
        let result = new Date(date)

        return result
      } else return null
    },

    async getAsssesment() {
      await this.$store
        .dispatch('getTPDAssessment', { id: this.$route.params.id })

        .then((response) => {
          //map existing data and display files
          this.displayExistingData(response.data)

          this.pageReady = true
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getNurseList() {
      let payload = { page: 1 }
      await this.$store
        .dispatch('getTPDNurseList', payload)
        .then((response) => {
          let data = response.data
          this.nurseList = data.results
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getSpecialistList() {
      let payload = { page: 1 }
      await this.$store
        .dispatch('getTPDSpecialistList', payload)
        .then((response) => {
          let data = response.data
          this.specialistList = data.results
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getDocList() {
      let payload = { page: 1 }
      await this.$store
        .dispatch('getTPDDoctorList', payload)
        .then((response) => {
          let data = response.data
          this.doctorList = data.results
        })
        .catch((error) => {
          console.log(error)
        })
    },

    displayExistingData(obj) {
      // Check if specialist is included in this selection
      this.specialist_included = obj.referral.service_requirement_choices.find(
        (x) => {
          return (
            x.service_type === obj.referral.service_requirement?.service_type
          )
        }
      ).include_specialist

      // Check if nurse is allowed for this service
      this.nurse_included = obj.referral.service_requirement_choices.find(
        (x) => {
          return (
            x.service_type === obj.referral.service_requirement?.service_type
          )
        }
      ).include_nurse

      // Update service and fees display
      obj.referral.service_requirement.ime_report
        ? this.serviceAndFeesInfo.push('Additional IME report')
        : ''
      obj.referral.service_requirement.mas_report
        ? this.serviceAndFeesInfo.push('Additional MAS report')
        : ''
      let found =
        this.$store.state.TPDTemplates.service_requirement_choices.find(
          (x) =>
            x.service_type === obj.referral.service_requirement.service_type
        )

      if (found) {
        this.serviceAndFeesInfo = this.serviceAndFeesInfo.concat(
          found.service_display_name.split(/[&,]/)
        )
      }
      // Map fees
      this.fee = this.$store.state.TPDTemplates.fee_choices.find(
        (x) => x.fee_type === obj.referral.fee_structure_type
      ).fee_display_name

      // Map patient details
      this.patient = obj.referral.patient

      // Map lawyer detials
      this.lawyer = obj.referral.referrer

      // Map docs from lawyer upload
      for (const [key, value] of Object.entries(obj.referral)) {
        let found = this.lawyerDocuments.find((x) => x.api_title === key)
        if (found) {
          found.value = value

          // Check if it has been downloaded before

          found.is_all_marked = value.every(
            (file) => file.last_download_at !== null
          )

          // Set to included status when detect files
          value.length > 0 ? (found.status = 'included') : 'unavailable'
        }
      }

      // Map new-reports
      this.newReports.value = obj.referral.new_reports

      // Map teleconsult dates or set defaults
      if (obj.teleconsult_appointment) {
        this.admin = obj.teleconsult_appointment
      } else {
        this.admin.nurse_appointment = dayjs()
          .add(1, 'day')
          .set('hour', 9)
          .set('minute', 0)
          .set('second', 0)

        this.admin.doctor_appointment = dayjs()
          .add(2, 'day')
          .set('hour', 9)
          .set('minute', 0)
          .set('second', 0)

        // Map specialist if its included
        if (this.include_specialist) {
          this.admin['specialist_appointment'] = dayjs()
            .add(4, 'day')
            .set('hour', 9)
            .set('minute', 0)
            .set('second', 0)
        }

        this.admin.specialist_appointment_duration = 75
      }
    },

    getFile(fileData) {
      axios
        .get(fileData.file, {
          responseType: 'blob',
        })
        .then((response) => {
          const href = URL.createObjectURL(response.data)

          // Create "a" HTLM element with href to file & click
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', fileData.file_name) //or any other extension
          document.body.appendChild(link)
          link.click()

          // Clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
    },

    downloadFile(fileObj) {
      let objectURL
      let fileKey = Object.keys(fileObj.value)

      fileKey.forEach((key) => {
        if (objectURL) {
          // Revoke the old object url to avoid using more memory than needed
          URL.revokeObjectURL(objectURL)
        }

        const file = fileObj.value[key]

        // Mark file
        this.markFileAsDownloaded(file.id)
      })

      return (this.lawyerDocuments[fileObj.index].is_all_marked = true)
    },

    async markFileAsDownloaded(id) {
      await this.$store
        .dispatch('markDoc', { id: id })
        .then((res) => {
          // Do a get call, and get a blob version of the files
          this.getFile(res.data)

          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `Marked ${res.data.file_name} as downloaded.`,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    onInputChange(e) {
      // For renaming file with useful metadata?

      let newFiles = e

      class UploadableFile {
        constructor(file) {
          this.file = file
          this.id = `${file.name}-${file.size}`
          this.type = `${file.type}`
          this.url = URL.createObjectURL(file)
          this.status = null
        }
      }
      let newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file))
        .filter((file) => !this.fileExists(file.id))
      this.files = this.files.concat(newUploadableFiles)

      // Reset so that selecting the same file again will still cause it to fire this change

      e = null
    },

    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId)
    },

    async patchTPDAdmin() {
      // At the moment, admin only will be patching appointments

      let payload = {
        payload: {
          teleconsult_appointment: this.admin,
          is_created_on_local_system: this.isLocallyMadeMedFile,
        },
        id: this.$route.params.id,
      }

      // Filter format for teleconsult
      if (payload.payload.teleconsult_appointment.nurse) {
        payload.payload.teleconsult_appointment['nurse_id'] =
          this.admin.nurse.id
      }

      if (payload.payload.teleconsult_appointment.doctor) {
        payload.payload.teleconsult_appointment['doctor_id'] =
          this.admin.doctor.id
      }

      if (payload.payload.teleconsult_appointment.specialist) {
        payload.payload.teleconsult_appointment['specialist_id'] =
          this.admin.specialist.id
      }

      // Temporary solution
      // Have to figure out a function direct map the value to user_id

      delete payload.payload.teleconsult_appointment['doctor']
      delete payload.payload.teleconsult_appointment['nurse']
      delete payload.payload.teleconsult_appointment['specialist']

      await this.$store
        .dispatch('patchTPDAdmin', payload)
        .then(() => {
          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `TPD Assessment #${this.$route.params.id} updated successfully!`,
          })

          // Add timing to go back to TPD List after saving
          // that matches the delay for the success toast
          setTimeout(
            () =>
              this.$router.push({
                name: 'tpd-list',
              }),
            2000
          )
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response)
            this.adminErrors = error.response.data.teleconsult_appointment

            // Trigger toast text
            this.$root.toasts.push({
              status: 'danger',
              content: `Error: ${error.response}!`,
            })
          } else if (error.request) {
            alert(`${error.request}`)
          } else {
            alert(`${error.message}`)
          }
        })
    },

    async submit() {
      await this.patchTPDAdmin()
    },
  },
}
</script>

<style lang="scss" scoped>
#createReferralBtn {
  max-width: 250px;
}

label {
  color: #747474;
}

.fs-8 {
  font-size: 0.8rem;
}

.circle {
  &-success {
    border-radius: 50%;
    background-color: #27c92f;
    min-height: 8px;
    min-width: 8px;

    display: inline-block;
  }
}
</style>
